//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Checkbox',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hintMessage: {
      type: String,
      default: 'Required.',
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: 'This field is not correct.',
    },
    valid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Array, Boolean],
      default: () => [],
    },
  },
  computed: {
    isChecked() {
      if (typeof this.selected === 'boolean') {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },
    computedMessageSlotName() {
      return this.messagesHandler(
        'show-error-message',
        'show-info-message',
        this.required ? 'show-hint-message' : ''
      );
    },
    computedMessage() {
      return this.messagesHandler(
        this.errorMessage,
        this.infoMessage,
        this.required ? this.hintMessage : ''
      );
    },
    computedMessageClass() {
      return this.messagesHandler(
        'sf-checkbox__message--error',
        'sf-checkbox__message--info',
        this.required ? 'sf-checkbox__message--hint' : ''
      );
    },
    nameWithoutWhitespace() {
      return this.name.replace(/\s/g, '');
    },
  },
  methods: {
    inputHandler() {
      if (typeof this.selected === 'boolean') {
        this.$emit('change', !this.selected);
      } else {
        let selected = [...this.selected];
        if (selected.includes(this.value)) {
          selected = selected.filter((value) => value !== this.value);
        } else {
          selected.push(this.value);
        }
        this.$emit('change', selected);
      }
    },
    messagesHandler(error, info, hint) {
      if (this.errorMessage && !this.valid) {
        return error;
      } else if (this.infoMessage && this.valid) {
        return info;
      } else if (this.hintMessage) {
        return hint;
      } else {
        return '';
      }
    },
  },
};
