











import {
  defineComponent,
} from '@nuxtjs/composition-api';
import {
  SfLink,
} from '@storefront-ui/vue';

// A quick to use <TermsLink /> component with defaults.
export default defineComponent({
  name: 'TermsLink',
  components: {
    SfLink,
  },
  props: {
    link: {
      type: String,
      required: false,
      default: '/customer-support/terms',
    },
    label: {
      type: String,
      required: false,
      default: 'terms and conditions',
    },
  },
});
