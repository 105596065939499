import { render, staticRenderFns } from "./Totals.vue?vue&type=template&id=5693c11a&scoped=true"
import script from "./Totals.vue?vue&type=script&lang=ts"
export * from "./Totals.vue?vue&type=script&lang=ts"
import style0 from "./Totals.vue?vue&type=style&index=0&id=5693c11a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5693c11a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Table: require('/var/www/modules/amplience/components/content-type/Table.vue').default})
