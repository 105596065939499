import { render, staticRenderFns } from "./ListOnBBX.vue?vue&type=template&id=74d37171&scoped=true"
import script from "./ListOnBBX.vue?vue&type=script&lang=ts"
export * from "./ListOnBBX.vue?vue&type=script&lang=ts"
import style0 from "./ListOnBBX.vue?vue&type=style&index=0&id=74d37171&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d37171",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Table: require('/var/www/modules/amplience/components/content-type/Table.vue').default})
