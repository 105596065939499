



























































































































import {
  defineComponent,
  ref,
  computed,
  useContext,
  PropType,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import Checkbox from '~/bbrTheme/components/molecules/Checkbox.vue';
import {
  SfImage,
  SfQuantitySelector,
} from '@storefront-ui/vue';
import type {
  ItemInterface, ValuationInterface, ListingPayloadInterface, TotalInterface,
} from '@cellar-services/types';
import MarketPriceGuidance from '@cellar-services/components/molecules/BBX/MarketPriceGuidance.vue';
import Totals from '@cellar-services/components/atoms/Totals.vue';
import TermsLink from '~/bbrTheme/components/atoms/TermsLink.vue';

export default defineComponent({
  name: 'ListOnBBX',
  components: {
    Nullable,
    SfImage,
    SfQuantitySelector,
    TermsLink,
    MarketPriceGuidance,
    Totals,
    Checkbox,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    valuation: {
      type: Object as PropType<ValuationInterface>,
      required: true,
    },
  },
  setup(props) {
    const context = useContext();

    // TODO: Store config value pending BE implementation
    const comissionRate = 10; // 10%

    const hasUserAgreedTC = ref<boolean>(false);
    const listing = ref<ListingPayloadInterface>({
      listing_price: props.item.market_price,
      cases_qty: 1,
    });

    const totalAmount = computed<number>(() => listing.value.listing_price * listing.value.cases_qty);
    const comissionTotal = computed<number>(() => comissionRate / 100 * totalAmount.value);

    const subtotals = computed<TotalInterface[]>(() => [
      {
        label: 'Total',
        value: totalAmount.value,
      },{
        label: `Commission fee ${context.$fpcent(comissionRate)}`,
        value: comissionTotal.value,
      },
    ]);

    const total = computed(() => ({
      label: 'After commission',
      value: totalAmount.value - comissionTotal.value,
    }));

    return {
      hasUserAgreedTC,
      listing,
      subtotals,
      total,
    }
  },
});
