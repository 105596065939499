

































import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import type { TotalInterface } from '@cellar-services/types';

export default defineComponent({
  name: 'Totals',
  props: {
    subtotals: {
      type: Array as PropType<TotalInterface[]>,
      default: (): TotalInterface[] => [],
    },
    total: {
      type: Object as PropType<TotalInterface>,
      required: true,
    },
  }
});
